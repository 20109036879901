import { property } from "lodash";

export interface Device
{
    id: string;
    accountEnabled: boolean,
    deviceName: string;
    operatingSystem: string;
    operatingSystemVersion: string;
    approximateLastSignInDateTime: string;
    createdDateTime: string;
    owner: { "displayName": string, "userPrincipalName": string } | null,
    persona: string,
    labels: { "icon":string,"label":string}[] | null;
}

export interface DevicePagination
{
    length: number;
    size: number;
    page: number;
    lastPage?: number;
    startIndex?: number;
    endIndex?: number;
}

export class DeviceFieldMappings
{
    EnrollmentType = {
        "windowsAzureADJoin": "Azure AD Joined",
        "windowsAutoEnrollment": "Auto Enrollment"
    }

    ComplianceState = {
        "compliant": "Compliant",
        "noncompliant": "Not Compliant"
    }

    HealthStatus = {
        'unknown': 'Unknown',
        'insufficientData': "Insufficient Data",
        'needsAttention': "Needs Attention",
        'meetingGoals': "Meeting Goals",
        'unknownFutureValue': "Unknown"
    }
}