import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmationConfig } from '@fuse/services/confirmation/confirmation.types';

@Component({
    selector     : 'fuse-confirmation-dialog',
    templateUrl  : './dialog.component.html',
    styles       : [
        `
            .fuse-confirmation-dialog-panel {

                @screen md {
                    @apply w-128;
                }

                .mat-mdc-dialog-container {

                    .mat-mdc-dialog-surface {
                        padding: 0 !important;
                    }
                }
            }
        `
    ],
    encapsulation: ViewEncapsulation.None
})
export class FuseConfirmationDialogComponent 
{

    /**
     * Constructor
     */
    constructor(public dialogRef: MatDialogRef<FuseConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FuseConfirmationConfig)
    {
    }

    showCode(): void
    {
        console.log(this.data.confirmationCodeEntered);
    }


    /**
     * On init
     */
    ngOnInit(): void
    {

    }
}
